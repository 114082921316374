<template>
  <div class="storeOverview">
    <div class="outlinelist">
      <div class="title">
        <div class="text">门店概览<span>{{ off_device_now }}</span></div>
        <div class="dayrange" @click="showClick">
          <div class="icon"><img src="@/assets/img/dayicon.png" alt=""></div>
          <div class="text">{{ dayrangeText ? dayrangeText : '请选择日期' }}</div>
        </div>
        <div class="dayrange" @click="storeFieldShowClick">
          <div class="icon"><img src="@/assets/img/field.png" alt=""></div>
          <div class="text">字段</div>
        </div>
      </div>
      <div class="listcont">
        <div class="thead">
          <template v-for="(item, index) in storeField" :key="index">
            <div class="item" v-if="!item.filter || item.checked">{{item.title}}</div>
          </template>
        </div>
        <div class="tbody">
          <van-list class="contscroll" v-model:loading="loading" :offset="30" :finished="finished" finished-text="没有更多了"
            @load="onLoad">
            <div class="item" v-for="(item, index) in list" :key="index" @click="todtail(item.more_url)">
              <template v-for="(fitem, findex) in storeField" :key="findex">
                <div class="contitem" v-if="!fitem.filter || fitem.checked">{{ item[fitem.field] }}
                  <van-icon class="icon" size="16px" name="arrow" />
                </div>
              </template>
            </div>
          </van-list>
        </div>
        <!-- <div class="loading" v-if="loading"><img src="@/assets/img/loading.gif" alt="">加载中。。。</div>
        <div class="finish" v-if="finished">没有更多了</div> -->
      </div>
    </div>


    <van-popup v-model:show="selectPupShow" position="bottom" round>
      <van-picker :title="pupData[1].title" :columns="pupData[0].data" :columns-field-names="dataType"
        :default-index="pickdef" @confirm="okClick" @cancel="Cancel" />
    </van-popup>

    <van-calendar v-model:show="dayPupShow" type="range" :min-date="new Date(2010, 1, 1)" :show-confirm="false"
      :allow-same-day="true" @confirm="onConfirm" />

    <van-popup v-model:show="storeFieldShow" position="bottom" round>
      <div class="titlebox">
        <!--        <button type="button" @click="storeFieldCancer">取消</button>-->
        <div class="title">筛选字段</div>
        <button type="button" @click="storeFieldOk">确认</button>
      </div>
      <div class="checkcont">
        <div class="checkfielddesc">已选字段<span>(至少保留一个)</span></div>
        <template v-for="(item, index) in tmpCheckObj" :key="index">
          <van-checkbox v-model="item.checked" @click="storeFieldChange(index)">{{item.title}}</van-checkbox>
        </template>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import mitts from "@/bus";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import API from "@/axios";

const router = useRouter();
const route = useRoute();

const list = ref([]);
const loading = ref(false);
const finished = ref(false);

const page = ref(1)
const date_type = ref("")
const store_date_range = ref("")
const dayPupShow = ref(false)
const dayrangeText = ref("")

const selectPupShow = ref(false)
const pupData = ref({})
const off_device_now = ref("")
const pickdef = ref()

const load_switch = ref(false)

const dataType = ref({
  text: 'title',
  val: 'val',
})

// 门店概况字段显示配置
const storeField = ref([{}])
const storeFieldShow = ref(false)
function storeFieldShowClick() {
  storeFieldShow.value = true;
}

// function storeFieldCancer() {
//   storeFieldShow.value = false;
// }

const storeFieldStorageName = ref('storeSummarizeField')
function storeFieldOk() {
  storeFieldShow.value = false;

  for (const [index, item] of Object.entries(storeField.value)) {
    if (tmpCheckObj.value[item.field]) {
      storeField.value[index]['checked'] = tmpCheckObj.value[item.field]['checked']
    }
  }

  localStorage.setItem(storeFieldStorageName, JSON.stringify(storeField.value))
}

const tmpCheckObj = ref({})
function storeFieldChange(index) {
  var checkNum = 0;
  for (const item of Object.entries(tmpCheckObj.value)) {
    if (item[1].checked) {
      checkNum = checkNum + 1
    }
  }

  if (checkNum < 1) {
    Toast.success('至少选择一个字段')
    tmpCheckObj.value[index].checked = !tmpCheckObj.value[index].checked
    return false
  }
}

const formatDate = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (values) => {
  const [start, end] = values;
  dayPupShow.value = false;
  store_date_range.value = `${formatDate(start)}~${formatDate(end)}`;
  dayrangeText.value = `${formatDate(start)}~${formatDate(end)}`;
  list.value = []
  page.value = 1
  date_type.value = "diy"
  loading.value = true;
  finished.value = false;

  setTimeout(() => {
    onLoad()
  }, 800)
};


// const handleScroll = (e) => {
//   if (e.srcElement.scrollTop + e.srcElement.offsetHeight + 1 >= e.srcElement.scrollHeight) {

//     loading.value = true;
//     finished.value = false;

//     setTimeout(() => {
//       onLoad()
//     }, 800)
//   }
// }


function onLoad() {
  if (load_switch.value) {
    setTimeout(function () {
      load_switch.value = 1;
      onLoaddata()
    }, 500);
  } else {
    load_switch.value = 1;
    onLoaddata();
  }
}



function onLoaddata() {

  API({
    url: "/api/storeSummarize",
    method: "post",
    data: {
      page: page.value,
      date_type: date_type.value,
      date_range: store_date_range.value,
      limit: 30
    },
  }).then((res) => {
    if (res.data.status == 200) {
      pupData.value = res.data.data.income_condition
      off_device_now.value = res.data.data.off_device_now

      let daylist = res.data.data.income_condition[0].data

      for (const item of daylist) {
        if (item.val == res.data.data.income_condition[0].default) {
          dayrangeText.value = item.title
        }
      }

      storeField.value = res.data.data.field_condition

      let storageFields = localStorage.getItem(storeFieldStorageName)
      if (storageFields) {
        let fieldAtKey = 'storeSummarizeFieldAt'
        let fieldAt = localStorage.getItem(fieldAtKey)
        if (fieldAt == res.data.data.field_at) {
          storeField.value = JSON.parse(storageFields)
        } else {
          localStorage.setItem(fieldAtKey, res.data.data.field_at)
        }
      }

      storeField.value.forEach((item) => {
        if (item.filter) {
          tmpCheckObj.value[item.field] = {'checked': item.checked, 'title': item.title}
        }
      })

      let newArr = [];
      list.value = list.value.concat(res.data.data.list);
      list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.id === ol.id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });

      list.value = newArr;

      page.value++;

      if (res.data.data.list.length === 0) {
        finished.value = true;
      } else {
        finished.value = false;
      }

      loading.value = false;

      // loading.value = false;
      // finished.value = true;

    } else {
      Toast.fail(res.data.msg);
    }
    load_switch.value = 0;
  })
}

function todtail(val) {
  router.push({
    path: "/event/event",
    query: { url: encodeURIComponent(val) },
  });
}

function showClick() {
  selectPupShow.value = true
}

function okClick(val, index) {
  selectPupShow.value = false
  pickdef.value = index
  if (val.val == "diy") {
    dayPupShow.value = true;
  } else {
    list.value = []
    page.value = 1
    date_type.value = val.val
    dayrangeText.value = val.title
    store_date_range.value = ""

    onLoad()
  }
}

function Cancel() {
  selectPupShow.value = false
}

onMounted(() => {
  mitts.emit("toptitle", false);

  setTimeout(() => {
    mitts.emit('navs', JSON.parse(route.query.navs));
    mitts.emit('navindex', 'home');
    mitts.emit("closeLoading");
  }, 500)
  // loading.value = true;
  // finished.value = false;
  // onLoad()
  // setTimeout(() => {
  //   loading.value = true;
  //   finished.value = false;
  //   onLoad()
  // }, 1000)

})

</script>

<style lang="less" scoped>
.storeOverview {
  height: 99%;
  box-sizing: border-box;
  padding: 20px;
}

.outlinelist {
  background: @font-white-color;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  display: flex;
  flex-flow: column;
  height: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;

    .text {
      font-size: @subtitle-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @subtitle-size;
      margin-right: 10px;

      span {
        font-size: 24px;
        color: @font-third-color;
        line-height: 24px;
        font-weight: normal;
      }

    }

    .more {
      background: @theme-color;
      font-size: @fontmini-size;
      font-family: "Bold";
      color: @font-white-color;
      line-height: @fontmini-size;
      width: 120px;
      height: 50px;
      border-radius: 25px;
    }
  }

  .dayrange {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    justify-content: flex-end;

    .icon {
      width: 36px;
      margin-right: 10px;

      img {
        width: 100%;
        display: block;
      }
    }

    .text {
      font-size: 24px;
      line-height: 24px;
      color: @font-secondary-color;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .dayrange:last-of-type {
    flex: none;
    margin-left: 10px;
  }

  .listcont {
    flex-grow: 1;
    height: 10px;
    display: flex;
    flex-flow: column;

    .thead {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #dfdfdf;

      .item {
        flex: 1;
        min-width: 0;
        font-size: @font-size;
        line-height: @font-size;
        margin-right: 10px;
        text-align: left;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
      }

      .item:first-of-type {
        flex: 1;
      }

      .item:nth-of-type(2) {
        flex: none;
        width: @font-size*8;
        margin-right: 30px;
        text-align: center;
      }

      .item:last-of-type {
        margin-right: 0;
        flex: none;
        width: @font-size*5;
      }
    }

    .tbody {
      flex-grow: 1;
      height: 10px;
      display: flex;
      flex-flow: column;

      // overflow-y: scroll;
      .contscroll {
        flex-grow: 1;
        height: 10px;
        overflow-y: scroll;
      }

      .item {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        position: relative;

        .contitem {
          flex: 1;
          min-width: 0;
          font-size: @font-size;
          line-height: @font-size;
          margin-right: 10px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          justify-content: space-between;

          .icon {
            height: 14px;
            padding: 0;
            display: none;
          }
        }

        .contitem:first-of-type {
          flex: 1;
        }

        .contitem:nth-of-type(2) {
          flex: none;
          width: @font-size*8;
          margin-right: 30px;
          display: flex;
          justify-content: center;
        }

        .contitem:last-of-type {
          margin-right: 0;
          flex: none;
          width: @font-size*5;

          .icon {
            display: block;
          }
        }
      }
    }
  }
}

.loading {
  width: 100%;
  padding: 10px 0;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    display: block;
    margin-right: 10px;
  }
}

.finish {
  width: 100%;
  padding: 10px 0;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
}
.titlebox {
  display: flex;
  padding: 20px;
  .title {
    flex: 1;
    text-align: center;
  }
  button {
    background: none;
  }
}
.checkfielddesc {
  padding: 20px 0;

  span {
    color: #999999;
  }
}
</style>